import React, { useEffect } from 'react';
import { OverlayProvider, SSRProvider } from 'react-aria';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { djedi, NodeContext } from 'djedi-react';
import { NextApiRequest, NextComponentType, NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Clickable from 'components/Clickable';
import Column from 'components/Column';
import Container from 'components/Container';
import Heading from 'components/Heading';
import Layout from 'components/Layout';
import Row from 'components/Row';
import useMembership from 'hooks/useMembership';
import useUser from 'hooks/useUser';
import { MembershipRole } from 'types/Changemkr';
import { PageContext } from 'types/PageContext';
import hasPrivilege from 'utils/hasPrivilege';

import 'styles/global.scss';

type PageProps = {
  language: string;
  nodes: unknown;
  auth?: boolean;
  role?: MembershipRole;
};

function App({ Component, pageProps }: AppProps<PageProps>) {
  if (pageProps.nodes != null) {
    djedi.addNodes(pageProps.nodes);
    djedi.injectAdmin();
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SSRProvider>
        <OverlayProvider>
          <NodeContext.Provider value={LANGUAGES[0]}>
            <GuardedApp Component={Component} pageProps={pageProps} />
          </NodeContext.Provider>
        </OverlayProvider>
      </SSRProvider>
    </QueryClientProvider>
  );
}

function GuardedApp({
  Component,
  pageProps,
}: {
  // eslint-disable-next-line
  Component: NextComponentType<NextPageContext, any, any>;
  pageProps: PageProps;
}): JSX.Element {
  const router = useRouter();
  const { isLoading: userIsLoading, isAuthenticated, user } = useUser();
  const { isLoading: membershipIsLoading, membership } = useMembership();

  const isLoading = userIsLoading || membershipIsLoading;

  useEffect(() => {
    if (pageProps.auth && !isLoading && !isAuthenticated) {
      router.push('/login/');
    }
  }, [isLoading, isAuthenticated, router, pageProps.auth]);

  if (pageProps.auth && isLoading) {
    return (
      <Layout>
        <Container>Loading...</Container>
      </Layout>
    );
  }

  if (pageProps.auth && !isAuthenticated) {
    return (
      <Layout>
        <Container>You are not authenticated</Container>
      </Layout>
    );
  }

  if (
    pageProps.auth &&
    pageProps.role &&
    isAuthenticated &&
    !hasPrivilege(membership?.role, pageProps.role)
  ) {
    return (
      <Layout>
        <Container>
          <Row>
            <Column center="horizontal" size={12}>
              <Heading>
                You need a {pageProps.role} or better membership to access this page
              </Heading>
              <br />
              <Clickable href="/">Return home!</Clickable>
            </Column>
          </Row>
        </Container>
      </Layout>
    );
  }

  // Product fruits conf
  const ProductFruits = dynamic(
    () => import('react-product-fruits').then(mod => mod.ProductFruits),
    { ssr: false },
  );

  return (
    <>
      {isAuthenticated && (
        <ProductFruits
          workspaceCode="CMO77aJCBGj32xbY"
          language="sv"
          user={{
            username: user.email,
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            role: membership?.role || '',
          }}
        />
      )}
      <Component {...pageProps} />
    </>
  );
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnMount: false, refetchOnWindowFocus: false } },
});

const LANGUAGES = ['sv-SE', 'en-US'];
const API_URL = process.env.DJANGO_API_URL ?? process.env.NEXT_PUBLIC_DJANGO_API_URL;

// * DJEDI CONFIGURATION

djedi.options.baseUrl = API_URL;
djedi.options.languages.default = LANGUAGES[0];
djedi.options.languages.additional = LANGUAGES.slice(1);
djedi.options.uri.defaults.scheme = 'i18n';

djedi.options.defaultRender = (
  state: {
    content?: unknown;
    error?: Error & { response?: { status: number } };
    type: 'loading' | 'error' | 'success';
  },
  _data: { language: string },
) => {
  switch (state.type) {
    case 'loading':
      return 'Loading…';
    case 'error':
      return `Failed to fetch content 😞 (${state.error?.response?.status || -1})`;
    case 'success':
      return state.content;
    default:
      return null;
  }
};

djedi.options.fetch = (url: string, options: RequestInit = { headers: {} }) => {
  const auth = Buffer.from(
    `${process.env.BASIC_AUTH_USER}:${process.env.BASIC_AUTH_PASSWORD}`,
  ).toString('base64');

  return fetch(url, {
    ...(process.env.BASIC_AUTH_USER == null || process.env.BASIC_AUTH_PASSWORD == null
      ? options
      : {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Basic ${auth}`,
          },
        }),
    credentials: 'include',
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ComponentType<P = {}, IP = P> = NextComponentType<PageContext, IP, P>;

export interface AppContext extends NextPageContext {
  Component: ComponentType;
  ctx: NextPageContext;
  nodes: Record<string, unknown>;
  req: NextApiRequest;
}

App.getInitialProps = async (appContext: AppContext) => {
  const { Component, ctx } = appContext;
  const language = ctx.locale ?? LANGUAGES[0];

  let pageProps = {};

  if (Component.getInitialProps != null) {
    const pageContext: PageContext = {
      ...ctx,
      language,
    };

    pageProps = await Component.getInitialProps(pageContext);
  }

  await djedi.prefetch();

  const nodes = djedi.track();

  return { pageProps: { language, ...pageProps, nodes } };
};

export default App;
