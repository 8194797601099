import { CSRF_COOKIE_NAME, MOCK_API_URL } from 'consts';
import cookies from 'js-cookie';

import { shouldUseMSW } from '../utils';

export const API_URL = shouldUseMSW()
  ? MOCK_API_URL
  : process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL;

export const ENDPOINTS = {
  'survey': {
    list: '/api/survey/',
    retrieve: (id: number) => `/api/survey/${id}/`,
  },
  'user-survey': {
    list: '/api/user-survey/',
    retrieve: (id: number) => `/api/user-survey/${id}/`,
    create: '/api/user-survey/submit-survey/',
    update: (id: number) => `/api/user-survey/${id}/update-answers/`,
  },
  'org-survey': {
    retrieve: (id: number) => `/api/org-survey/${id}/`,
    results: (id: number, groups?: number[]) => {
      let baseURL = `/api/org-survey/${id}/results/`;
      if (groups) {
        const groupsQueryParam = groups.map(group => encodeURIComponent(group)).join(',');
        baseURL += `?groups=${groupsQueryParam}`;
      }
      return baseURL;
    },
    create: (orgId: number) => `/api/org-survey/${orgId}/create/`,
    update: (orgSurveyId: number) => `/api/org-survey/${orgSurveyId}/update/`,
  },
  'plan': {
    list: (orgId: number) => `/api/plan/list/${orgId}`,
    retrieve: (id: number) => `/api/plan/${id}/`,
    create: `/api/plan/create/`,
    update: (id: number) => `/api/plan/${id}/update/`,
    createFromSurvey: (id: number) => `/api/plan/create-from-survey/${id}/`,
    createFromOrgSurvey: (orgSurveyId: number) =>
      `/api/plan/${orgSurveyId}/create_plan_from_org_survey/`,
  },
  'auth': {
    'me': '/api/auth/me/',
    'login': '/api/auth/login/',
    'logout': '/api/auth/logout/',
    'register': '/api/auth/register/',
    'activate-user': (token: string) => `/api/auth/activate-user/${token}/`,
    'accept-invitation': '/api/auth/accept-invitation/',
    'forgot-password': (email: string) => `/api/auth/forgot-password/${email}/`,
    'reset-password': '/api/auth/reset-password/',
  },
  'user': {
    'update-profile': '/api/user/update-profile/',
    'set-image': '/api/user/set-image/',
  },
  'tasks': {
    'retrieve': (id: number) => `/api/task/${id}/`,
    'update': (id: number) => `/api/task/${id}/update/`,
    'bulk-update': '/api/task/bulk-update/',
    'create': '/api/task/',
    'delete': (id: number) => `/api/task/${id}/delete/`,
    'create-comment': (id: number) => `/api/task/${id}/comment/`,
    'update-comment': (id: number) => `/api/task/comment/${id}/update/`,
    'delete-comment': (id: number) => `/api/task/comment/${id}/delete/`,
    'previews': (id: number) => `/api/task/${id}/previews/`,
  },
  'organization': {
    'set-digital-destination': (orgId: number) => `/api/org/set-digital-destination/${orgId}/`,
    'update-organization': (orgId: number) => `/api/org/${orgId}/update/`,
    'retrieve': (orgId: number) => `/api/org/${orgId}/`,
    'set-image': (orgId: number) => `/api/org/${orgId}/set-image/`,
    'create-invite': (orgId: number) => `/api/org/${orgId}/invite/`,
    'delete-invite': (orgId: number, userId: number) =>
      `/api/org/${orgId}/invite/${userId}/delete/`,
    'delete-user': (orgId: number, userId: number) => `/api/org/${orgId}/${userId}/delete/`,
    'pre-test': '/api/org/pre-test/',
    'update-pre-test': (orgId: number) => `/api/org/pre-test/${orgId}/`,
    'create-group': (orgId: number) => `/api/org/${orgId}/create-group/`,
    'update-group': (orgId: number, groupId: number) =>
      `/api/org/${orgId}/update-group/${groupId}/`,
    'delete-group': (orgId: number, groupId: number) =>
      `/api/org/${orgId}/delete-group/${groupId}/`,
    'update-member': (orgId: number) => `/api/org/${orgId}/update-member/`,
    'create': '/api/org/create/',
  },
  'plan-template': {
    'list': '/api/plan-template/list/',
    'create': '/api/plan-template/create/',
    'update': (planId: number) => `/api/plan-template/update/${planId}/`,
    'delete': (planId: number) => `/api/plan-template/delete/${planId}/`,
    'create-plan': (planTemplateId: number, orgId: number) =>
      `/api/plan-template/${planTemplateId}/create-plan/${orgId}/`,
    'add-task': `/api/plan-template/add-task/`,
    'remove-task': (planTemplateId: number, taskTemplateId: number) =>
      `/api/plan-template/${planTemplateId}/remove-task/${taskTemplateId}/`,
    'reorder-task': (planTemplateId: number) =>
      `/api/plan-template/${planTemplateId}/reoder-tasks/`,
  },
  'task-template': {
    'list': '/api/task-template/list/',
    'create': '/api/task-template/create/',
    'update': (taskTemplateId: number) => `/api/task-template/${taskTemplateId}/update/`,
    'delete': (taskId: number) => `/api/task-template/delete/${taskId}/`,
    'create-task': (taskTemplateId: number, planId: number) =>
      `/api/task-template/${taskTemplateId}/create-task/${planId}/`,
  },
  'journeys': {
    list: (orgId: number) => `/api/journeys/${orgId}/`,
    create: `/api/journeys/create/`,
    delete: (journeyId: number) => `/api/journeys/${journeyId}/delete/`,
    update: (journeyId: number) => `/api/journeys/${journeyId}/update/`,
  },
  'preview': (url: string) => '/api/preview?url=' + encodeURIComponent(url),
  'ai-threads': {
    get: (orgId: number) => `/api/ai-thread/org/${orgId}`,
    post: `/api/ai-thread/`,
    delete: (threadId: string) => `/api/ai-thread/${threadId}`,
  },
} as const;

export const CSRF_TOKEN_HEADER = {};

Object.defineProperty(CSRF_TOKEN_HEADER, 'X-CSRFToken', {
  get: () => cookies.get(CSRF_COOKIE_NAME),
  enumerable: true,
});
